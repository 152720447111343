@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  background-color: #f0f2f5;
  font-family: "Josefin Sans", sans-serif;
  overflow-x: hidden; 
}


#loader-container {
	width: 4rem;
	height: 4rem;
	position: relative;
	border-radius: 11px;
	animation: spin 1s ease infinite;
	&:hover {
		background: #303030;
	}
	&::before, &::after {
		content: '';
		width: 40%;
		height: 40%;
		display: block;
		position: absolute;
		border-radius: 9px;
	}
	&::before {
		top: 3px;
		left: 3px;
		background: #047B69;
	}
	&::after {
		bottom: 3px;
		right: 3px;
		background: #c9d9ca;
	}
}

@keyframes spin {
	from {
		transform: rotate(135deg);
	}
	to {
		transform: rotate(495deg);
	}
}
.svg-green path {
  fill : #047b69
}
.App {
  text-align: center;
}

.svg-white path {
  fill: white;
}
.svg-red path {
  fill : red;
}
.svg-black path {
  fill : black;
}
.svg-gray path {
  fill: gray;
}
.svg-green path {
  fill: #047b69;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.score-radar-chart{
  width:100%;
  border-radius:100px;
  overflow:hidden; 
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.blur-layer {
  background-color: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(10px); /* Adjust the blur intensity */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  border-radius: 10px;
  padding: 32px;
  position: relative;
  z-index: 999;
}
.App-link {
  color: #61dafb;
}

.toggle-switch {
  position: relative;
  display: flex;
  align-items: center;
}
.toggle-label {
  padding-left: 48px;
}
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ececec;
    transition: 0.4s;
    border-radius: 34px;
    white-space: nowrap;
    padding-left: 40px;
    height: 20px;
    width: 40px;
    max-width: 40px;
    border: 0.5px solid #c9c9c9;
}

.toggle-slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .toggle-slider {
    background-color: #000;
}

input:checked + .toggle-slider:before {
    transform: translateX(20px);
}

/* custom radio button css  */

.radio-container {
  display: flex;
  align-items: center;
  padding: 13px;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  cursor: pointer;
}

.radio-input {
  display: none;
}

.radio-input + .radio-label::before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 11px;
  border: 2px solid #f1ebf5;
  border-radius: 50%;
  vertical-align: middle;
  transition: border 0.3s ease;
  position: absolute;
  left: 0px;
  box-shadow: 0 4px 8px 0px rgba(28, 37, 44, 8%);
}
.radio-label {
  position: relative;
  padding-left: 45px;
}

.radio-input:checked + .radio-label::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #047b69;
  border-radius: 50%;
  position: absolute;
  left: 8px;
  transition: all 0.5s ease;
}

.radio-input.job-zone:checked + .radio-label.job-zone-label::after {
  top: 8px;
}
.loader div {
  animation-duration: 0.7s;
}

.loader div:first-child {
  animation-delay: 0.1s;
}

.loader div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader div:nth-child(3) {
  animation-delay: 0.3s;
}

.google-login-btn * {
  font-family: "Questrial", "sans-serif";
  border: none !important;
  color: #000 !important;
  font-size: 12px !important;
}
.google-login-btn *:hover {
  background: transparent !important;
}
.google-login-btn *:focus * {
  background-color: transparent !important;
}
.google-login-btn *:hover * {
  background-color: transparent !important;
}

.career-list li a {
  padding: 13px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.career-list li:first-child a {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #dee2e6;
}
.career-list li:last-child a {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid #dee2e6;
}
.career-list li:nth-child(odd) {
  background-color: transparent;
}
.career-list li:nth-child(even) {
  background-color: #f0f2f591;
}
 
.share-btn {
  background-color:  #047b69;
  padding: 5px 8px;
  color: white;
  box-shadow: none;
  border-right: none;
  font-family: "Josefin Sans";
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border: 1px solid #047b69;
  border-left: none;
}
.custom-btn-wrapper {
  box-shadow: none;
}

.css-188cy1e-MuiGrid-root {
  margin-top: 0px;
  row-gap: 25px;
}
.css-noh3rg-MuiPaper-root {
  width: 100%;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  font-weight: 600;
  color: #007a6b;
  font-family: "Josefin Sans", sans-serif;
} 
.search-list {
  border-radius: 5px;
}
.search-list li:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.search-list li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.custom-select-box {
  max-width: calc(100% - 58px);
}
.modal-form.w-full {
  width: 100%;
}
.custom-modal h2.font-medium {
  font-weight: 600;
  font-family: Josefin Sans, sans-serif !important;
}
.custom-modal .custom-button.bg-primary-green {
  background-color: #007a6b;
}
.custom-modal .custom-button.shadow-none {
  box-shadow: none;
  font-size: 14px;
  font-family: "Josefin Sans";
} 
ul.custom-style-base li {
  padding-left: 40px;
}
ul.custom-style-base li:before {
  position: absolute;
  margin-left: -1.5em;
  padding-right: 1em;
  color: rgba(25, 5, 0, 0, 1);
  content: "\25CF";
}
ul.reset-style {
  list-style: none;
}
ul.reset-style li {
  margin-bottom: 4px;
}
ul.reset-style li:last-child {
  margin-bottom: 0px;
}
.custom-btn.bg-primary-green {
  background-color: #007a6b;
  box-shadow: none;
  border-radius: 5px;
  padding: 6px 25px;
}
.custom-btn.bg-primary-green:hover {
  background-color: #007a6b;
  box-shadow: none;
}
.pagination-tab button {
  box-shadow: none;
  text-transform: capitalize;
  border-color: #007a6b;
  color: #007a6b;
}
.pagination-tab .MuiButton-root.MuiButton-contained {
  background-color: #007a6b;
  color: #fff;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #007a6b !important;
}
.custom-btn-wrapper button {
  border: #007a6b;
  background-color: #007a6b;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Josefin Sans", sans-serif;
}
.custom-btn-wrapper button:hover {
  background-color: #007a6b;
}
.custom-btn-wrapper button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.custom-btn-wrapper button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid #fff;
}
.custom-btn-wrapper {
  box-shadow: none !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #007a6b !important;
}
.custom-btn {
  background-color: #007a6b !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 16px !important;
}
.job-outlook-heading b {
  color: #b33800;
}
.career-interest-description b {
  color: #007a6b;
}

/* Navbar.css */

.mobile-menu {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transform: scaleY(0);
  transition: height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
}

.mobile-menu.open {
  height: auto; /* Allow height to grow */
  opacity: 1;
  transform: scaleY(1);
}

.mobile-menu.closed {
  height: 0;
  opacity: 0;
  transform: scaleY(0);
}

#rssBlock{ 
  width:100%; 
  overflow: hidden;
}

/*remove p*/
.cnnContents {
  width:100%;
  padding-top: 20px;
  margin:0 auto;
  font-size: 200px;
  white-space: nowrap;
  text-transform: uppercase; 
  font-weight: 300;
}

.marqueeStyle {
  display: inline-block;
  /* Start visible, and scroll after 2s */
  animation: scrolling-left1 20s linear infinite;
  animation-delay: 2s; /* Delay before scrolling starts */
}

.marqueeStyle2 {
  display: inline-block;
  /* Start visible, and scroll after 2s */
  animation: scrolling-left2 20s linear infinite;
  animation-delay: 2s; /* Delay before scrolling starts */
}


.globe-container > div{
    width:100% !important;
    height:100% !important;
    overflow:visible !important;
}
.home-parallex{
    background-image: url("/src/images/homePageBg.jpeg"); 
    min-height: 500px;  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode:darken; 
} 
.home-bg-radiant{
    background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,           
    rgba(0, 0, 0, 0.9) 5%,           
    rgba(0, 0, 0, 0.76) 10%,           
    rgba(0, 0, 0, 0.6) 15%,           
    rgba(0, 0, 0, 0.45) 25%,           
    rgba(0, 0, 0, 0.3) 35%,         
    rgba(0, 0, 0, 0.3) 45%,        
    rgba(0, 0, 0, 0.3) 75%,        
    rgba(0, 0, 0, 0.3) 80%,        
    rgba(0, 0, 0, 0.4) 85%,        
    rgba(0, 0, 0, 0.6) 90%,        
    rgba(0, 0, 0, 1) 100%        
  );
}
.parallax-content{
  z-index : 100;
}
.home-hero {
    overflow: hidden;
    z-index: 2;
    position: relative;
    height: 375vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-left1 {
  0% {
    transform: translateX(0); /* Start fully visible */
  } 
  100% {
    transform: translateX(-100%); /* Move out of view */
  }
}

@keyframes scrolling-left2 {
  0% {
    transform: translateX(0); /* Start fully visible */
  } 
  100% {
    transform: translateX(-100%); /* Move further for the second marquee */
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 
@media all and (max-width: 768px) {
  .timeline .right_content svg ,.centered-logo svg{
    max-height: 300px;
  }
}
@media print {
  .MuiDialog-root {
    display: none !important;
  }
  .printable-section {
    height: auto;
  }
  .MuiGrid-root.MuiGrid-item {
    page-break-inside: avoid;
    /* Optional: to avoid breaking at the start or end of an element */
    page-break-before: auto;
    page-break-after: auto;
  }
  .print-button, .hide-when-print{
    display:none !important;
  }
  .print-input{
    border:none;
  }
}

.home-hero {
    overflow: hidden;
    z-index: 2;
    position: relative;
    height: 375vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover
}

.home-hero .bgr-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh
}

.home-hero .bgr-holder span {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.home-hero .bgr-holder span.middle,.home-hero .bgr-holder span.front {
    opacity: 0
}

.home-hero .holder {
    height: 125vh;
    margin: 0 auto
}

.home-hero .holder .decoration {
    position: absolute
}

.home-hero .holder .decoration span {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #d3a888;
    border-radius: 50%
}

.home-hero .holder.top .decoration {
    top: 11.3541666667vw;
    left: 7.5vw;
    opacity: 0;
    width: 33.75vw;
    height: 33.75vw;
    -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5,.5);
    -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5,.5);
    -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5,.5);
    -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5,.5);
    transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5,.5);
    -moz-transition: 2100ms cubic-bezier(.215,.61,.355,1);
    -o-transition: 2100ms cubic-bezier(.215,.61,.355,1);
    -webkit-transition: 2100ms cubic-bezier(.215,.61,.355,1);
    transition: 2100ms cubic-bezier(.215,.61,.355,1);
    -moz-transition-property: opacity,transform;
    -o-transition-property: opacity,transform;
    -webkit-transition-property: opacity,transform;
    transition-property: opacity,transform
}

.home-hero .holder.top h1 {
    color: #000
}

.home-hero .holder.top h1 span {
    position: absolute;
    overflow: hidden;
    padding-right: 1.0416666667vw;
    font-size: 10.5208333333vw;
    line-height: 10.5208333333vw;
    text-transform: uppercase;
    letter-spacing: -.05em;
    font-weight: 300
}

.home-hero .holder.top h1 span em {
    display: block;
    font-style: normal;
    -webkit-transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    -moz-transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    -ms-transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    -o-transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    -moz-transition: transform 1500ms cubic-bezier(.215,.61,.355,1);
    -o-transition: transform 1500ms cubic-bezier(.215,.61,.355,1);
    -webkit-transition: transform 1500ms cubic-bezier(.215,.61,.355,1);
    transition: transform 1500ms cubic-bezier(.215,.61,.355,1)
}

.home-hero .holder.top h1 span:first-child {
    top: 10.7291666667vw;
    left: 2.7083333333vw
}

.home-hero .holder.top h1 span:nth-child(2) {
    top: 20.7291666667vw;
    left: 46.3541666667vw;
    font-family: "Cormorant Garamond",Times,serif;
    font-size: 11.3541666667vw;
    line-height: 11.3541666667vw;
    font-weight: 400
}

.home-hero .holder.top h1 span:nth-child(2) em {
    -moz-transition-delay: 200ms;
    -o-transition-delay: 200ms;
    -webkit-transition-delay: 200ms;
    transition-delay: 200ms;
    font-style: italic
}

.home-hero .holder.top h1 span:last-child {
    top: 38.5416666667vw;
    left: 15.4166666667vw
}

.home-hero .holder.top h1 span:last-child em {
    -moz-transition-delay: 400ms;
    -o-transition-delay: 400ms;
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms
}

.home-hero .holder.top p {
    position: absolute;
    left: 16.5625vw;
    top: 29.6875vw;
    width: 23.9583333333vw;
    line-height: 1.5625vw;
    text-transform: uppercase;
    letter-spacing: .1041666667vw;
    color: #000
}

.home-hero .holder.top p span {
    display: block;
    overflow: hidden
}

.home-hero .holder.top p span em {
    display: block;
    -webkit-transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    -moz-transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    -ms-transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    -o-transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    transform: translateX(0) translateY(100%) translateZ(0) rotate(0deg) scale(1,1);
    -moz-transition: transform 1500ms 400ms cubic-bezier(.215,.61,.355,1);
    -o-transition: transform 1500ms 400ms cubic-bezier(.215,.61,.355,1);
    -webkit-transition: transform 1500ms 400ms cubic-bezier(.215,.61,.355,1);
    transition: transform 1500ms 400ms cubic-bezier(.215,.61,.355,1)
}

.home-hero .holder.top p span em:nth-child(2) {
    -moz-transition-delay: 400ms;
    -o-transition-delay: 400ms;
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms
}

.home-hero .holder.top p strong {
    font-family: "Cormorant Garamond",Times,serif;
    font-weight: 700;
    font-size: 1.0416666667vw
}

.home-hero .holder.middle .decoration {
    opacity: 1;
    top: 3.3854166667vw;
    left: 33.8541666667vw;
    width: 42.96875vw;
    height: 42.96875vw
}

.home-hero .holder.middle h2 {
    color: #fff
}

.home-hero .holder.middle h2 span {
    position: absolute;
    overflow: hidden;
    padding-right: 1.0416666667vw;
    font-size: 10.5208333333vw;
    line-height: 10.5208333333vw;
    text-transform: uppercase;
    letter-spacing: -.05em;
    font-weight: 300
}

.home-hero .holder.middle h2 span em {
    display: block;
    font-style: normal
}

.home-hero .holder.middle h2 span:first-child {
    top: 8.5416666667vw;
    left: 7.2916666667vw
}

.home-hero .holder.middle h2 span:nth-child(2) {
    top: 21.09375vw;
    right: 6.25vw;
    font-family: "Cormorant Garamond",Times,serif;
    font-size: 11.3541666667vw;
    line-height: 11.3541666667vw;
    font-weight: 400
}

.home-hero .holder.middle h2 span:nth-child(2) em {
    font-style: italic
}

.home-hero .holder.middle h2 span:last-child {
    top: 36.9791666667vw;
    left: -3.6458333333vw
}

.home-hero .holder.middle p {
    position: absolute;
    right: -2.6041666667vw;
    top: 36.9791666667vw;
    width: 23.4375vw;
    line-height: 1.5625vw;
    text-transform: uppercase;
    letter-spacing: .1041666667vw;
    color: #fff
}

.home-hero .holder.middle p span {
    display: block;
    overflow: hidden
}

.home-hero .holder.middle p span em {
    display: block
}

.home-hero .holder.middle p strong {
    font-family: "Cormorant Garamond",Times,serif;
    font-weight: 700;
    font-size: 1.0416666667vw
}

.home-hero .holder.bottom .decoration {
    top: 2.6041666667vw;
    left: 27.8125vw;
    width: 42.96875vw;
    height: 42.96875vw
}

.home-hero .holder.bottom h2 {
    color: #fff
}

.home-hero .holder.bottom h2 span {
    position: absolute;
    font-size: 10.5208333333vw;
    line-height: 10.5208333333vw;
    text-transform: uppercase;
    letter-spacing: -.05em;
    font-weight: 300
}

.home-hero .holder.bottom h2 span em {
    display: block;
    font-style: normal
}

.home-hero .holder.bottom h2 span:first-child {
    top: 11.25vw;
    left: 33.75vw
}

.home-hero .holder.bottom h2 span:nth-child(2) {
    top: 21.6666666667vw;
    left: 2.5vw;
    font-family: "Cormorant Garamond",Times,serif;
    font-size: 11.3541666667vw;
    line-height: 11.3541666667vw;
    font-weight: 400
}

.home-hero .holder.bottom h2 span:nth-child(2) em {
    font-style: italic
}

.home-hero .holder.bottom h2 span:last-child {
    top: 36.9791666667vw;
    left: 15.3645833333vw
}

.home-hero .holder.bottom p {
    position: absolute;
    left: 45.625vw;
    top: 29.1666666667vw;
    width: 16.1458333333vw;
    line-height: 1.5625vw;
    text-transform: uppercase;
    letter-spacing: .1041666667vw;
    color: #fff
}

.home-hero .holder.bottom p strong {
    font-family: "Cormorant Garamond",Times,serif;
    font-weight: 700;
    font-size: 1.0416666667vw
}

.home-hero .scroll-indicator {
    position: absolute;
    right: 2.1875vw;
    top: calc(100vh - 5.75vw)
}
.globe-container .scene-container{
    margin: auto;
}
 

 

@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 40s;
 
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}
 