@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  background-color: #f0f2f5;
  font-family: "Josefin Sans", sans-serif;
}
*:focus {
  outline: none;
}
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  position: absolute;
  opacity: 0;
  transition: opacity 0.6s ease-out;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}

#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet {
  color: #428bca;
}
.answer-list-wrapper {
  position: relative;
  margin-left: 10px;
}
.answer-list-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 2px;
  background-color: #f2f2f2;
}
.answer-list-wrapper > * {
  margin-left: 40px;
  max-width: calc(100% - 40px);
}
.answer-list-wrapper > div:last-child {
  margin-bottom: 0px;
}
.answer-list-wrapper > div:first-child {
  margin-top: 0px;
}
.chat-box-icon {
  left: -55px !important;
  z-index: 999;
  padding: 6px;
  border-radius: 50%;
  background-color: #fafaff;
}
.chat-box-icon .relative svg {
  right: -7px;
  top: -23px;
}
.answer-list-wrapper > div:first-child .chat-box-icon {
  top: 0px !important;
}
.answer-list-wrapper .answer-list-wrapper .chat-box-icon {
  top: 10px !important;
}
.reply-wrapper .user-profile img {
  position: relative;
}
.reply-wrapper .user-profile img::before {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateX(-50%);
  content: "";
  display: inline-block;
  width: 2px;
  height: 1px;
}
/* .replied-box{
    position: relative;
   }
   .replied-box::before{
    position: absolute;
    left: 23px;
    display: inline-block;
    content: '';
    background-image: url(../images/chat-reply-dooted-line.png);
    background-repeat: repeat-y;
    background-size:auto;
    height: 100%;
    width: 64px;
   } */
/* .reply-wrapper>*{
    margin-left: 65px;
   } */
.replied-box .reply-item {
  position: relative;
}
.replied-box .reply-item::before {
  display: inline-block;
  content: "";
  border-left: 2px dashed #e5e5e5;
  height: 100%;
  width: 64px;
  position: absolute;
  left: 20px;
  top: 0px;
}
.reply-wrapper .reply-item {
  position: relative;
  padding-left: 65px;
}
.reply-wrapper .reply-item:last-child::before {
  height: 50px;
}
.reply-wrapper .reply-item::before {
  display: inline-block;
  content: "";
  border-left: 2px dashed #e5e5e5;
  height: 100%;
  width: 64px;
  position: absolute;
  left: 20px;
  top: 6px;
}
.reply-wrapper hr {
  max-width: calc(100% - 65px);
}
.reply-wrapper .user-profile-photo {
  position: relative;
}
.reply-wrapper .user-profile-photo::before {
  position: absolute;
  left: -40px;
  display: inline-block;
  content: "";
  border-top: 2px dashed #e5e5e5;
  width: 40px;
}
.blackSvg path {
  fill: #000;
}
.replied-box > hr {
  max-width: calc(100% - 40px);
  margin-left: auto;
}

.adminTable {
  max-height: calc(100vh - 355px);
}

/* Animation styles */
@keyframes scale-up {
  0% {
    transform: scale(1.5) rotateY(180deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotateY(720deg);
    opacity: 1;
  }
}

.animated-svg {
  width: 150px;
  height: 150px;
  animation: scale-up 1s ease-out forwards;
}

/* Optional stroke animation */
.animated-svg path {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: stroke-draw 2s ease-out forwards;
}

@keyframes stroke-draw {
  to {
    stroke-dashoffset: 0;
  }
}

.disabled,
.Mui-disabled {
  cursor: not-allowed !important;
}

.icon-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden; /* Prevents the arrow from being visible outside bounds */
  width: 30px; /* Adjust based on SVG size */
  height: 20px;
}

.arrow-icon {
  position: absolute;
  left: -100%; /* Start off-screen */
  animation: slide-right 1s ease-in-out infinite; /* Animation for smooth movement */
}

@keyframes slide-right {
  0% {
    left: 0%; /* Start off-screen */
  }
  50% {
    left: 20%; /* Midway */
  }
  100% {
    left: 0%; /* End off-screen on the right */
  }
}
@media all and (max-width: 767px) {
  .replied-box::before,
  .replied-box .reply-item::before {
    display: none;
  }
  .custom-button {
    font-size: 11px !important;
  }
  .reply-wrapper .reply-item {
    padding-left: 0px;
  }
  .reply-wrapper .user-profile-photo::before {
    display: none;
  }
  .answer-list-wrapper > * {
    margin-left: 28px;
  }
  .chat-box-icon {
    left: -40px !important;
  }
}

/* updated CSS */
.login-box {
  min-height: calc(-150px + 100vh);
}
.login-page-bottom-links a {
  white-space: nowrap;
  display: inline-block;
  padding: 0px 10px 15px 0px;
  position: relative;
  margin-right: 8px;
}
.login-page-bottom-links a::before {
  content: "";
  width: 1px;
  height: 13px;
  background: rgb(0, 0, 0);
  position: absolute;
  right: 0px;
  top: 3px;
}
.login-page-bottom-links a:last-child:before {
  display: none;
}
.custom-btn {
  letter-spacing: 0.1em;
}

.toggle-switch input[type="checkbox"] {
  display: none;
  cursor: pointer;
}
.toggle-switch input[type="checkbox"] + label {
  position: relative;
}
.toggle-switch input[type="checkbox"] + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid rgb(101, 121, 244);
  border-radius: 4px;
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 3px;
}
.toggle-switch input[type="checkbox"]:checked + label::before {
  background-color: rgb(101, 121, 244);
}
.toggle-switch input[type="checkbox"]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 4px;
  height: 10px;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-check-box label {
  position: relative;
  cursor: pointer;
}

.custom-check-box input[type="checkbox"] {
  visibility: hidden;
  opacity: 0;
  height: 0px;
  width: 0px;
}
.custom-check-box label {
  user-select: none;
}
.custom-check-box label:before {
  content: "";
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 8px 0px rgba(28, 37, 44, 0.08);
  width: 26px;
  height: 26px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 3px;
}

.custom-check-box input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: 11px;
  width: 6px;
  height: 14px;
  border: 1px solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

@media all and (max-width: 576px) {
  input[type="checkbox"] + label:before {
    margin-top: 1px;
  }
  input[type="checkbox"]:checked + label:after {
    top: 0;
  }
}
.overview-card .text-custom-light-yellow {
  color: #d4af37;
}

/* Dashboard Page css start */
aside .active {
  background-color: #047b69;
  border-radius: 14px;
}
aside .active svg path {
  fill: #fff;
}
aside {
  font-family: "Questrial", "sans-serif";
}

aside .active span {
  color: #fff;
}
.main-content-wrapper {
  margin-left: auto;
  padding: 20px 36px;
}
.sidebar-close-button {
  margin-left: 7px;
}
.main-content-wrapper.sidebar-open {
  width: calc(100% - 260px);
}
.main-content-wrapper.sidebar-close,
.main-content-wrapper.small-screen {
  width: 100%;
}
.sidebar-content-wrapper::-webkit-scrollbar {
  width: 0; /* Minimize width */
  height: 0; /* Minimize height for horizontal scrollbar */
}

.sidebar-content-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Customize thumb color */
  border-radius: 10px;
}

.sidebar-content-wrapper::-webkit-scrollbar-track {
  background: transparent; /* Optional: Change track background */
}

.custom-popup-close {
  background: #000 !important;
  border-radius: 50% !important;
  padding: 0px !important;
}
.modal-open {
  overflow: hidden;
}

.file {
  position: relative;
}

.file > input[type="file"] {
  display: none;
}

.file label {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: #4c535f;
  border-style: dashed;
  border-radius: 14px;
  border-width: 1px;
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 29%);
  padding: 18px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button[aria-selected="true"] {
  color: #047b69;
  font-weight: bold;
  border-bottom-color: #047b69;
}
button[aria-selected="true"]:hover {
  color: #047b69 !important;
}
@media all and (max-width: 1024pfx) {
  .main-content-wrapper {
    padding: 15px;
  }
  .sidebar-close-button {
    margin-left: 15px;
  }
}

.my-facebook-button-class {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 15px 20px;
  align-items: center;
  background: white;
  width: max-content;
  height: 60px;
  border-radius: 30px;
  border: 1px solid rgb(0 0 0 / 0.1);
}

.MuiList-root {
  height: 100%;
  max-height: 400px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #f0f2f5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0);
  border: none;
}

.industry-distribution-wrapper .recharts-legend-wrapper {
  max-height: 100px;
  overflow-y: auto;
}
@media all and (max-width: 768px) {
  .main-content-wrapper {
    margin-left: auto;
    padding: 20px 25px;
  }
}
