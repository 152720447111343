@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
body{
  background-color: #F0F2F5;
  font-family: "Josefin Sans", sans-serif;
  overflow-x: hidden;
}

.lottie-wrapper.black svg path {
  fill : black; 
  stroke : black; 
}

.lottie-wrapper.white svg path {
  fill : rgba(245, 245, 220, 1); 
  stroke : rgba(245, 245, 220, 1); 
}

.ellipsis-tooltip {
  display: inline-block;
  max-width: 100%;      
  white-space: nowrap;   
  overflow: hidden;      
  text-overflow: ellipsis;    
}
 .logos-slider .slick-slide {
  display:flex;
  justify-content:center;
  align-items:center;
  padding : 3rem;
 }

.gradient-border {
  position: relative;
  padding: 1px; /* Space for the gradient border */
  border-radius: 120px; /* Adjust border radius */
  background: linear-gradient(45deg, #2D2D2D 70%, #FFFFFF 100%);
}
.faq-gradient-border{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 1px;
  top: 1px;
  border-radius: 60px; /* Adjust border radius */
  background: linear-gradient(158deg, #2D2D2D 90%, #FFFFFF 100%);
}
.leftshade-gradient-border{
  position: relative;
  padding: 1px; /* Space for the gradient border */
  border-radius: 120px; /* Adjust border radius */
  background: linear-gradient(280deg, #2D2D2D 70%, #FFFFFF 100%);
}
.gradient-border > .content {
  position: relative;
  border-radius: inherit; /* Same border radius for the inner content */
  background: #000; /* Background of the content */
  padding:20px 60px; /* Padding inside the content */
}
.gradient-border > .content .middle-link{
  padding-left: 84px;
  padding-right: 84px;
  border-left: 1px solid rgba(255, 255, 255, 0.24);
  border-right: 1px solid rgba(255, 255, 255, 0.24);
}
.lo-bottom-text{
  color: rgba(255, 255, 255, 0.5);
  font-size: clamp(20px, 8.9vw, 173px);
  line-height: 0.8;
}
.tooltip {
  top: -0.5rem; /* Adjust this to change tooltip position */
}
.custom-tooltip{
  background: rgba(255, 255, 255, 0.13); /* Semi-transparent white background */
  border-radius: 10px;
  padding: 27px;
  backdrop-filter: blur(10px); /* Apply the blur effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 405px;
}
.tooltip {
  position: absolute;
  pointer-events: none; /* Prevents the tooltip from interfering with mouse events */
  transition: opacity 0.3s; 
}

.tooltip-show {
  opacity: 1; /* Show tooltip */
}

.container{
  max-width: 1835px;
  margin-left: auto;
  margin-right: auto;
  padding-left:15px;
  padding-right:15px;
}
.custom-menu .active{
  font-weight: bold;
}
.custom-menu a{
  font-size: 60px;
  text-transform: uppercase;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}
.custom-menu a .acitve-hover-border{
   height: 7px;
   background-color: #fff;
   width: 65px;
   display: inline-block;
   margin-top: -15px;
   opacity: 0;
   transition: opacity 0.5s ease;
}
.cursor-follow {
  width: 53px;
  height: 53px;
  background-color: rgba(212, 175, 55, 0.1);
  position: absolute;
  border-radius: 50%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
  backdrop-filter: blur(100%);
  -webkit-backdrop-filter: blur(100%);
  /* box-shadow: 160px 160px 100px 160px #ffffff; */
  box-shadow: 2px 15px 100px rgba(212, 175, 55, 1);
}

.custom-menu a.active .acitve-hover-border,.custom-menu a:hover .acitve-hover-border{
  opacity: 1;
}
.custom-menu{
  text-align: left;
  width: 70%;
  padding-left: 15px;
  padding-right: 15px;
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.menu-open{
  overflow: hidden;
}
.main-header{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  padding: 0;
  -moz-transition: 350ms cubic-bezier(.215, .61, .355, 1);
  -o-transition: 350ms cubic-bezier(.215, .61, .355, 1);
  -webkit-transition: 350ms cubic-bezier(.215,.61,.355,1);
  transition: 350ms cubic-bezier(.215,.61,.355,1);
}
.main-header.bg-black{
  background-color: #000;
}
.scrolling-down .main-header {
  -webkit-transform: translateX(0) translateY(-100%) translateZ(0) rotate(0deg) scale(1, 1);
  -moz-transform: translateX(0) translateY(-100%) translateZ(0) rotate(0deg) scale(1, 1);
  -ms-transform: translateX(0) translateY(-100%) translateZ(0) rotate(0deg) scale(1, 1);
  -o-transform: translateX(0) translateY(-100%) translateZ(0) rotate(0deg) scale(1, 1);
  transform: translateX(0) translateY(-100%) translateZ(0) rotate(0deg) scale(1, 1);
}
.circle-border-box{
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(245, 245, 220, 1);
  border-radius: 50%;
}
/* .combine-section-bg{
  background-image: url('public/enriching.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} */
.leading-normal{
 line-height: normal !important;
}
.decoration{
  position: absolute;
  top: 8.3541666667vw;
  left: 7.5vw;
  opacity: 0;
  width: 40vw;
  height: 40vw;
  -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5, .5);
  -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5, .5);
  -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5, .5);
  -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5, .5);
  transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(.5, .5);
  -moz-transition: 2100ms cubic-bezier(.215, .61, .355, 1);
  -o-transition: 2100ms cubic-bezier(.215, .61, .355, 1);
  -webkit-transition: 2100ms cubic-bezier(.215,.61,.355,1);
  transition: 2100ms cubic-bezier(.215,.61,.355,1);
  -moz-transition-property: opacity, transform;
  -o-transition-property: opacity, transform;
  -webkit-transition-property: opacity, transform;
  transition-property: opacity, transform;
  opacity: 1;
  -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1, 1);
  -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1, 1);
  -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1, 1);
  -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1, 1);
  transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1, 1);
}
.about-us-banner-decoration{
  width: 33vw;
  height: 33vw;
  top: -4.948vw;
  right: 0.5vw !important;
}
.right-decoration{
  left: auto;
  right: 4.5vw;
}
.scrolling-up .main-header{
  background-color: #000 !important;
} 
.centered-text{
      transform: translateY(calc(-50% - -20px));
}
.responsive-text{
  font-size: 7.5208333333vw;
}
.minus .vertical {
  opacity: 0; /* Hide vertical line to convert + to - */
}

.vertical, .horizontal {
  stroke: currentColor;
  stroke-width: 5;
}

.timeline:before {
  content: '';
  position:absolute;
  height:90%;
  left:50%;
  width:2px;
  top:5%;
  background:#fff;
  z-index:1;
}
.timeline{
  position:relative;
  margin: 0px auto;
  width:100%;
  padding:10px 0;
}


.timeline ul{
  margin:0;
  padding:0 ;
}
.timeline .right_content{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.timeline ul li{
  list-style:none;
  box-sizing:border-box; 
  line-height:normal;
  position:relative;
  width:50%;
  padding: 0px 80px;
  padding-bottom: 80px;
}

.timeline ul li:nth-child(even) .right_content{
  align-items: flex-start;
}
.timeline ul li .right_content h2{
  color:#fff;
  padding:0px;
}
.timeline ul li:nth-child(odd){
  float:left;
  text-align:right;
  clear:both;
}

.timeline ul li:nth-child(even){
  float:right;
  text-align:left;
  clear:both;
}

.left_content{
  padding-bottom:20px;
}

.timeline ul li:nth-child(odd):before{
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  right: -13px;
  top: 24px;
  z-index: 1;
  border: 7px solid #000;
}

.timeline ul li:nth-child(even):before{
  content: '';
  position:absolute;
  width: 25px;
  height: 25px;
 background:#fff;
  border-radius:50%;
  left:-12px;
  top:24px;
  border: 7px solid #000;
  z-index: 99;
}

.timeline ul li:nth-child(odd) .left_content{
  position:absolute;
  top:8px;
  right:-430px;
  margin:0;
  padding:8px 16px;
  color:#fff;  
}

.timeline ul li:nth-child(even) .left_content{
  position:absolute;
  top:10px;
  left:-370px;
  margin:0;
  padding:8px 16px;
  color:#fff;
}

.box-hover-gradient-border{
  position: absolute;
  top: -1px;
  left: 0px;
  height: calc(100% + 1px);
  width: calc(100% + 1px);
  border-radius: 30px !important;
  z-index: 10;
  opacity: 0;
  transition: all 0.5s ease;
  background:linear-gradient(45deg, #2D2D2D 90%, #FFFFFF 100%);
}
.box-hover-gradient-border.job-position-border{
  top: -1px !important;
  left: -1px !important;
  height: calc(100% + 2px) !important;
  width: calc(100% + 2px) !important;
}
.accordion-item{
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 20px 0px;
  border-bottom: 1px solid #939393;
}
.custom-hover-border-box:hover .box-hover-gradient-border {
  opacity: 1;
}
.custom-list,.custom-list li{
  list-style: disc;
}
.accordion-heading.active .minus-icon{
   display: inline-block !important;
}
.accordion-heading.active  .plus-icon,.accordion-heading .minus-icon{
  display: none;
}
.accordion-heading svg{
  margin-left: auto;
}
.counter-hidden{
  opacity: 0;
}
.accordion-content {
  transition: max-height 0.3s ease;
  overflow: hidden;
}
.MuiButtonBase-root.MuiPickersDay-today,.MuiDayCalendar-weekContainer button[aria-selected='true'],.MuiPickersYear-yearButton.Mui-selected{
  background: rgba(212, 175, 55, 1) !important;
  color: #fff !important;
}
.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition{
  min-height: 300px;
}
.MuiButtonBase-root.MuiPickersDay-root{
  font-size: 19px !important;
  color: rgba(51, 51, 51, 1);
  font-family: 'Inter', 'sans-serif';
  font-weight: 300;
  width: 55px !important;
  height: 55px !important;
}
.MuiDayCalendar-header{
  border-bottom: 1px solid rgba(229,229,229,1) !important;
}
.MuiPickersArrowSwitcher-rightArrowIcon,.MuiPickersArrowSwitcher-leftArrowIcon{
  color: #000;
  font-size: 30px !important;
}
.MuiDayCalendar-weekDayLabel{
  font-size: 19px !important;
}
.slick-track{
  display: flex !important;
  align-items: stretch;
}
.slick-initialized .slick-slide>div{
  width: 100%;
}
.green-custom-list li::before{
  color: #047b69;
}
/* Custom CSS slider */
@keyframes scroll {
  0% {
    transform: translateX(0);
}
100% {
    transform: translateX(calc(-250px* 7));
}
}
.slider {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
  border-top: 1px solid rgba(255, 255, 255, 0.14);
  max-width: 100vw;
}
.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
  gap: 75px;
  padding-top: 65px;
  padding-bottom: 65px;
  align-items: center;
}
/* Ensure the slick-slider uses flex properties to make height equal */
.slick-track {
  display: flex;
  align-items: stretch; /* Stretch all items to be the same height */
}

.slick-slide {
  display: flex !important;
  align-items: stretch;
  height: auto !important; /* Ensure full height */                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             ;
}


.custom-slider-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure the items inside stretch to fill the height */
  height: 100%; /* Full height for each item */
}

.slider .slide {
  width: 250px;
}
.fixed-gradient-border{
  opacity: 1 !important;
}
.fixed-right-gradient-border{
  left: -2px;
  background: linear-gradient(303deg, #2D2D2D 90%, #FFFFFF 100%);
  opacity: 1 !important;
}
.bottom-right-gradient-border{
  top: 0px;
  background: linear-gradient(123deg, #2D2D2D 90%, #FFFFFF 100%);
  opacity: 1 !important;
}
.list-style-number,.list-style-number li{
  list-style:decimal;
}
.bottom-left-gradient-border{
  left: -2px !important; 
  top: 0px !important;
  background: linear-gradient(210deg, #2D2D2D 90%, #FFFFFF 100%);
  opacity: 1 !important;
}
.custom-white-svg,.custom-white-svg path{
  fill: white;
} 
.faq-border {
  opacity: 1;
  border-radius: 60px !important;
  left: 0px !important;
  top: -1px !important;
}

.css-jrvpzm-MuiModal-root-MuiDialog-root .MuiDialog-container>div{
  max-width: 1216px !important;
  border-radius: 20px !important; 
}
.custom-slider-item{
  background-color: #000;
  z-index: 20;
  border-radius: 20px;
  border:1px solid #353535;
}
svg {
  max-width: 100%;
} 
 
@media all and (max-width:1024px){
  .timeline ul li:nth-child(odd) .left_content{
    right: -210px;
    top: 15px;
  }
  .timeline ul li:nth-child(even) .left_content{
    left: -175px;
    top: 18px;
  }
  .logos-slider .slick-slide { 
    padding : 2rem;
   }
  
}
@media (max-width: 1000px){
  
  .timeline{
    width:100%;
  }
  .logos-slider .slick-slide { 
    padding : 2rem;
   }
}


@media (max-width: 767px){
  .timeline{
    width:100%;
    margin-top: 60px;
  }
  .timeline ul{
    padding-left: 50px;
  }
  .timeline ul li{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .timeline ul li .right_content h2{
    width: 100%;
    text-align: left;
  }
  .timeline:before{
    left:20px;
  }
  .timeline ul li:nth-child(odd),
  .timeline ul li:nth-child(even){
    width:100%;
    text-align:left;
   padding-left:50px; 
  }
  
  .timeline ul li:nth-child(odd):before
    {
    top:-18px;
    left:0;
  
  }
  .timeline ul li:nth-child(even):before
  { 
    top:-18px;
    left:0;
  }
  
    .timeline ul li:nth-child(odd) .left_content,
  .timeline ul li:nth-child(even) .left_content
  {
    top:-28px;
    left:0;
    right:inherit;
  }

  .logos-slider .slick-slide { 
    padding : 1rem;
   }
}





@media all and (max-width:1366px){
    .custom-menu a{
        font-size: 50px;
    }
}
@media all and (max-width:1200px){
    .gradient-border > .content{
        padding: 20px 50px;
    }
    .gradient-border > .content .middle-link{
        padding-left: 16px;
        padding-right: 16px;
    }
    .custom-menu a{
        font-size: 40px;
    }
}

@media all and (max-width:768px){
  .timeline ul li {
    padding: 0px 30px;
}
  .mt-60px {
    margin-top: 30px;
}
  .timeline ul li{
    padding-bottom: 10px !important;
  }
  .timeline ul li:nth-child(3){
    padding-bottom: 0px !important;
  }
    .custom-tooltip{
        max-width: 220px;
        padding: 12px;
    } 
    .custom-tooltip{
        left: 50% !important;
        transform: translateX(-50%);
    }
    .custom-menu a{
        font-size: 20px;
    }
    .custom-menu{
        gap: 15px;
    }
    .custom-menu a .acitve-hover-border{
        height: 3px;
        width: 30px;
        margin-top: -7px;
    }
}

@media all and (max-width:575px){
  .gradient-border > .content {
    padding:12px 21px 8px 21px ;
  }
  .timeline ul {
    padding-left: 25px;
}
.timeline:before {
  left: 5px;
  top : 0;
  height : 100%;
}
.about-us-banner-decoration .circle-border-box {
  width: 70%;
  height: 70%;
}
.circle-border-box{ 
  border: 1px solid rgba(245, 245, 220, 0.5); 
}
}

@media all and (max-width:520px){
    .custom-tooltip{
        left: 50px !important;
    }
}




@layer utilities {
  .menu-slide-in {
    animation: height-in 0.5s forwards;
  }
  .menu-slide-out {
    animation: height-out 0.5s forwards;
  }
  @keyframes height-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes height-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
